import actionTypes, { baseURL } from "../actionTypes";
import axios from "axios";
// import filter from "lodash/filter";
import find from "lodash/find";
import { isMobile, isIOS, isIOS13 } from "react-device-detect";

// Earlier webscraping from IG api open graph ql was done but not any more as it is not allowed by instagram

export const getBusinessNameFromUrl = () => {
  //api to fetch detail based on url
  // console.log('window url', window.location.href);
  /**
   *  URL For testing can be by uncommenting any 1 url from below
   */
  // var url = "https://lavistaperfumes.optimizeapp.com/";
  // var url = "https://uniquehypekw.stage.optimizeapp.com/";
  // var url = "https://imran0702.optimizeapp.com";
  // var url = "https://jsbakery.optimizeapp.com";
  //var url = "https://imiotest.optimizeapp.com";
  //var url = "https://testing.optimizeapp.com";
  // var url = "https://uniquehypekw.optimizeapp.com";
  // var url = "https://alajmanalyoom.optimizeapp.com";
  //var url = "https://finstyle.optimizeapp.com";
  // var url = "https://atory.optimizeapp.com";
  //var url = "https://willcafe.optimizeapp.com";
  // var url = "https://apreskw.optimizeapp.com";
  // var url = 'https://jrkw.optimizeapp.com';
  // var url = 'https://bnorders.optimizeapp.com';
  // var url = 'https://lobsterlake.optimizeapp.com';
  // var url = "https://nushin.optimizeapp.com";
  //var url = "https://marashkwt.optimizeapp.com/";
  // var url = "https://chocolateandkarak.optimizeapp.com/"
  // var url = "https://testsk.optimizeapp.com/"
  // var url = "https://haykalah.optimizeapp.com/";
  // var url = "https://januaryeleven.optimizeapp.com/"
  // var url = "https://aljambamusic.optimizeapp.com/"
  // var url = "https://fashioniconq8.optimizeapp.com"
  // var url = "https://ikarusoud.optimizeapp.com/"
  // var url = "https://candle2020.optimizeapp.com/"
  // var url = "https://bonabeel.optimizeapp.com/"
  // var url = "https://standardgoodskw.optimizeapp.com/"
  // var url = "https://mamolq8.optimizeapp.com/"
  // var url = "https://nudekw.optimizeapp.com/"
  // var url = "https://shaheenmusic.optimizeapp.com/"
  // var url = "https://afashionillustrathion.optimizeapp.com/"
  // var url = "https://afashionillustrathion.optimizeapp.com/"
  // var url = "https://amaryllsperfumes.optimizeapp.com/"
  // var url = "https://abayatalghali.optimizeapp.com/"
  // var url = "https://withibrahim.optimizeapp.com/"
  // var url = "https://withibrahim.optimizeapp.com/"
  // var url = "https://withibrahim.optimizeapp.com/"
  // var url = "https://khairatuae.optimizeapp.com/"
  // var url = "https://alwidadperfume.optimizeapp.com"
  // var url = "https://lanaalkamaljewelry.optimizeapp.com"
  // var url = "https://manjaraty.optimizeapp.com/"
  // var url = "https://bluelinekw.optimizeapp.com/"
  // var url = "https://nupfkw.optimizeapp.com"
  // var url = "https://gomenassat.optimizeapp.com/"
  // var url = "https://charahstudio.optimizeapp.com/"
  // var url  = "https://silverenia.optimizeapp.com"
  //var url = "https://imran0702.optimizeapp.com/";
  // var url = "https://ttaiefinkw.optimizeapp.com"
  // var url = "https://drsondosalsaleh.optimizeapp.com/";
  // var url = "https://thoubhoney.optimizeapp.com/"
  // var url = "https://q8health.optimizeapp.com/";
  // var url = "https://filariaoud.optimizeapp.com/"
  // var url = "https://markaonline.optimizeapp.com/"
  // var url = "https://specialdishesq8.optimizeapp.com/"
  // var url = "https://byqamraa.optimizeapp.com/"
  // var url = "https://rivalstrk.optimizeapp.com/";
  // var url = "https://dropsartkw.optimizeapp.com/";
  // var url = "https://mysweetq8.optimizeapp.com/";
  // var url = "https://marayimabaya.optimizeapp.com/";
  // var url = "https://babusaudi.optimizeapp.com/";
  // var url = "https://samyoptimize.optimizeapp.com?preview=internal";
  // var url = "https://mkaer.optimizeapp.com";
  /**
   * Comment thos for testing
   */
  var pathname = new URL(window.location.href).host;

  //**
  //  * Uncomment this for testing
  //  */
  // var pathname = new URL(url).host;

  const businessName = pathname.split(".")[0];
  // console.log('businessName', businessName);

  //return "imran.0702";
  return businessName;
};

// const getInstagramImages = async insta_handle => {
//   try {
//     var response = await axios.get(`https://www.instagram.com/${insta_handle}`);
//     if (response && response.data) {
//       var data = response.data;
//       data = data.split("window._sharedData = ");
//       data = data[1].split("</script>");
//       data = data[0];
//       data = data.substr(0, data.length - 1);
//       data = JSON.parse(data);
//       data =
//         data.entry_data.ProfilePage[0] &&
//         data.entry_data.ProfilePage[0].graphql.user;
//       // console.log('data', JSON.stringify(data));
//       // const mediaArray = [];

//       const mediaList = data.edge_owner_to_timeline_media;

//       let hasNextPage = mediaList.page_info.has_next_page;
//       let end_cursor = mediaList.page_info.end_cursor;
//       // console.log('mediaList', mediaList);
//       // mediaArray.push(...mediaList.edges);
//       // let hasNextPage = mediaList.page_info.has_next_page;
//       // let end_cursor = mediaList.page_info.end_cursor;
//       // To load all images at once
//       // while (hasNextPage) {
//       // 	console.log('hasNextPage', hasNextPage);

//       // 	// (async () => {
//       // 	const responseMedia = await axios.get(
//       // 		`https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${
//       // 			data.id
//       // 		}","first":${50},"after":"${end_cursor}"}`
//       // 	);
//       // 	console.log(
//       // 		'responseMedia data',
//       // 		responseMedia.data.data.user.edge_owner_to_timeline_media
//       // 	);
//       // 	mediaArray.push(...responseMedia.data.data.user.edge_owner_to_timeline_media.edges);
//       // 	hasNextPage =
//       // 		responseMedia.data.data.user.edge_owner_to_timeline_media.page_info.has_next_page;
//       // 	end_cursor = responseMedia.data.data.user.edge_owner_to_timeline_media.page_info.end_cursor;
//       // 	// }, 10000);
//       // }
//       // console.log('mediaArrayLength', mediaArray);

//       if (mediaList.edges && mediaList.edges.length > 0) {
//         var imagesList = mediaList.edges.map(media => {
//           // console.log('media', media);

//           return {
//             imageUrl: media.node.display_url,
//             shortcode: media.node.shortcode,
//             imageId: media.node.id,
//             // thumbnail: media.node.thumbnail_src,
//             productDescription:
//               media.node.edge_media_to_caption.edges.length > 0
//                 ? media.node.edge_media_to_caption.edges[0].node.text
//                 : "",
//             isVideo: media.node.is_video
//           };
//         });
//         // console.log('imageListBeforeSize', imagesList.length);

//         // imagesList = filter(imagesList, media => {
//         //   return !media.isVideo;
//         // });

//         return {
//           imagesList,
//           username: data.username,
//           id: data.id,
//           full_name: data.full_name,
//           hasNextPage: hasNextPage,
//           end_cursor: end_cursor
//         };
//         // console.log('imageListAfterSize', imagesList.length);
//       }
//       return {
//         imagesList: [],
//         username: data.username,
//         id: data.id,
//         full_name: data.full_name,
//         hasNextPage: hasNextPage,
//         end_cursor: end_cursor

//       };
//     }
//   } catch (error) {
//     console.log(
//       "Error While retreivinggetInstagramImages ",
//       error.response || error.message
//     );
//     // Logic here to hit api for tracking errors
//     // errorLoadingPage(getBusinessNameFromUrl(), error.message || error.response || "Oops! Something went wrong.")
//     return {
//       imagesList: [],
//       username: "",
//       id: null,
//       full_name: null,
//       hasNextPage: false,
//       end_cursor: null
//     };
//   }
// };

export const getInstagramAccountDetail = (history, productId) => {
  //debugger;
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: true,
      });
      const businessName = getBusinessNameFromUrl();

      const response = await axios.get(
        // `https://www.optimizeapp.com/optimize/public/webinfo/${businessName}`
        `${baseURL}webinfo/${businessName}`
        //`${baseURL}webinfo/${businessName}/businesswebProducts`
        // `https://optimizekwtestingserver.com/optimize/public/webinfo/${businessName}`// OLD API,
      );
      if (response && response.data) {
        let information = response.data;
        console.log("info", information);
        if (information.success) {
          if (isMobile && (isIOS || isIOS13)) {
            information.whatsapp_link_url = "whatsapp://send?";
          } else {
            information.whatsapp_link_url = "https://api.whatsapp.com/send?";
          }

          // FOR INSTAGRAM TRAFFIC
          if (businessName === "alajmanalyoom") {
            const accountInfo = {
              businessid: information.data.businessinfo.business_id,
              businessName,
              brandName: information.data.businessinfo.business.name,
              brandProfilePic: information.data.businessinfo.businesslogo,
              web_heading_key_name:
                information.data.businessinfo.web_heading_key_name,
              button_icon: information.data.businessinfo.button_icon,
              button_text: information.data.businessinfo.button_text,
              button_action:
                information.data.businessinfo.button_action.toLowerCase(),
              button_message: information.data.businessinfo.button_message,
              username: null,
              id: null,
              imagesList: [],
              productDetail: {
                imageUrl: null,
                shortcode: null,
                imageId: null,
                thumbnail: null,
                caption: null,
                isVideo: null,
              },
              snap_pixel_script:
                information.data.businessinfo.snap_pixel_script,
              telephoneNumber: information.data.businessinfo.callnumber,
              whatsappLink: `${
                information.whatsapp_link_url
              }phone=${information.data.businessinfo?.whatsappnumber?.replace(
                "+",
                ""
              )}`,
              whatsappnumber: information.data.businessinfo.whatsappnumber,
              instagramLink: `https://www.instagram.com/${information.data.businessinfo.insta_handle}`,
              insta_handle: information.data.businessinfo.insta_handle,
              googlemaplink: information.data.businessinfo.googlemaplink,
              tiktokLink: `https://www.tiktok.com/${information.data.businessinfo.tiktok_handle}`,
              tiktok_handle: information.data.businessinfo.tiktok_handle,
              instagram_campaign_enabled:
                information.data.businessinfo.instagram_campaign_enabled,
              snapchat_handle: information.data.businessinfo.snapchat_handle,
              twitter_handle: information.data.businessinfo.twitter_handle,
              no_of_products_to_show: information.data.no_of_products_to_show,
              youtube_handle: information.data.businessinfo.youtube_handle,
              viewMore: information.data.businessinfo.viewMore,
              buttonInstagram: information.data.businessinfo.buttonInstagram,
              buttonWhatsapp: information.data.businessinfo.buttonWhatsapp,
              buttonPhone: information.data.businessinfo.buttonPhone,
              buttonSnapchat: information.data.businessinfo.buttonSnapchat,
              buttonTwitter: information.data.businessinfo.buttonTwitter,
              buttonWebsite: information.data.businessinfo.buttonWebsite,
              buttonMap: information.data.businessinfo.buttonMap,
              buttonCalendar: information.data.businessinfo.buttonCalendar,
              buttonYoutube: information.data.businessinfo.buttonYoutube,
              showIconButtonNames:
                information.data.businessinfo.showIconButtonNames === 1,
              websitelink: information.data.businessinfo.websitelink,
              facebook_handle: information.data.businessinfo.facebook_handle,
              website_url: information.data.businessinfo.website_url,
            };
            return dispatch({
              type: actionTypes.SET_INSTA_ACCOUNT_DETAILS,
              payload: accountInfo,
            });
          } else if (
            // information.data.businessinfo.instagram_campaign_enabled === 1 ||
            // information.data.businessinfo.whatsapp_campaign_enabled === 0
            true
          ) {
            // check if productList exist in dB products saved from APP

            var webproducts = information.data.products_insta_feed || [];

            // if (webproducts.length > 0) {
            // console.log('web prod', webproducts);
            //   debugger;
            var productDetail = null;
            if (productId) {
              productDetail = find(
                webproducts,
                (item) => item.imageId == productId
              );
            }

            console.log("productDetail", productId, productDetail, webproducts);

            // logic for hiding products to hide
            if (
              information.data.products_to_hide &&
              information.data.products_to_hide.webproducts
            ) {
              const products_to_hide_list = JSON.parse(
                information.data.products_to_hide.webproducts
              );
              if (products_to_hide_list && products_to_hide_list.length > 0) {
                // remove hidden products
                webproducts = webproducts.filter((objFromImagesList) => {
                  return !products_to_hide_list.includes(
                    objFromImagesList.imageId
                  );
                });
              }
            }

            const accountInfo = {
              businessid: information.data.businessinfo.business_id,

              businessName,
              web_heading_key_name:
                information.data.businessinfo.web_heading_key_name,
              button_icon: information.data.businessinfo.button_icon,
              button_text: information.data.businessinfo.button_text,
              button_action:
                information.data.businessinfo.button_action.toLowerCase(),
              button_message: information.data.businessinfo.button_message,
              brandName: information.data.businessinfo.business.name,
              brandProfilePic: information.data.businessinfo.businesslogo,
              username: information.data.businessinfo.insta_handle,
              imagesList: webproducts,
              // id: data.id,
              // isPrivate: data.is_private,
              // isVerified: data.is_verified,
              // biography: data.biography,
              // followedBy: data.edge_followed_by.count,
              // following: data.edge_follow.count,
              // imagesList,
              productDetail: productDetail
                ? productDetail
                : {
                    imageUrl: null,
                    shortcode: null,
                    imageId: null,
                    thumbnail: null,
                    productDescription: null,
                    isVideo: null,
                  },
              snap_pixel_script:
                information.data.businessinfo.snap_pixel_script,
              googlemaplink: information.data.businessinfo.googlemaplink,
              telephoneNumber: information.data.businessinfo.callnumber,
              whatsappnumber: information.data.businessinfo.whatsappnumber,
              whatsappLink: `${
                information.whatsapp_link_url
              }phone=${information.data.businessinfo?.whatsappnumber?.replace(
                "+",
                ""
              )}`,
              //whatsappLink: `https://api.whatsapp.com/send?phone=${information.data.businessinfo.whatsappnumber}`,
              instagramLink: `https://www.instagram.com/${information.data.businessinfo.insta_handle}`,
              tiktokLink: `https://www.tiktok.com/${information.data.businessinfo.tiktok_handle}`,
              tiktok_handle: information.data.businessinfo.tiktok_handle,
              instagram_campaign_enabled:
                information.data.businessinfo.instagram_campaign_enabled,
              snapchat_handle: information.data.businessinfo.snapchat_handle,
              twitter_handle: information.data.businessinfo.twitter_handle,
              insta_handle: information.data.businessinfo.insta_handle,
              no_of_products_to_show: information.data.no_of_products_to_show,
              youtube_handle: information.data.businessinfo.youtube_handle,
              viewMore: information.data.businessinfo.viewMore,
              buttonInstagram: information.data.businessinfo.buttonInstagram,
              buttonWhatsapp: information.data.businessinfo.buttonWhatsapp,
              buttonPhone: information.data.businessinfo.buttonPhone,
              buttonSnapchat: information.data.businessinfo.buttonSnapchat,
              buttonTwitter: information.data.businessinfo.buttonTwitter,
              buttonMap: information.data.businessinfo.buttonMap,
              buttonCalendar: information.data.businessinfo.buttonCalendar,
              buttonYoutube: information.data.businessinfo.buttonYoutube,
              websitelink: information.data.businessinfo.websitelink,
              showIconButtonNames:
                information.data.businessinfo.showIconButtonNames === 1,
              buttonWebsite: information.data.businessinfo.buttonWebsite,
              facebook_handle: information.data.businessinfo.facebook_handle,
              website_url: information.data.businessinfo.website_url,
            };
            // console.log("accountInfo productList exist", accountInfo);

            if (history.location.search.indexOf("preview=internal") === 1) {
              //show directly
            } else if (
              information.data.businessinfo?.website_suspended === 1 ||
              (information.data.businessinfo?.review_status &&
                information.data.businessinfo.review_status !== "approved")
            ) {
              history.push({ pathname: "/404" });
            }

            return dispatch({
              type: actionTypes.SET_INSTA_ACCOUNT_DETAILS,
              payload: accountInfo,
            });
            // }
          } // to check for whatsapp campaign
          else if (
            information.data.businessinfo.whatsapp_campaign_enabled === 1 &&
            information.data.businessinfo.instagram_campaign_enabled === 0
          ) {
            // check if productList exist in dB products saved from APP

            webproducts = information.data.products_insta_feed || [];

            // if (webproducts.length > 0) {
            // console.log('web prod', webproducts);
            if (productId) {
              productDetail = find(
                webproducts,
                (item) => item.imageId === productId
              );
            }

            // logic for hiding products to hide
            if (
              information.data.products_to_hide &&
              information.data.products_to_hide.webproducts
            ) {
              const products_to_hide_list = JSON.parse(
                information.data.products_to_hide.webproducts
              );
              if (products_to_hide_list && products_to_hide_list.length > 0) {
                // remove hidden products
                webproducts = webproducts.filter((objFromImagesList) => {
                  return !products_to_hide_list.includes(
                    objFromImagesList.imageId
                  );
                });
              }
            }

            const accountInfo = {
              businessid: information.data.businessinfo.business_id,

              businessName,
              web_heading_key_name:
                information.data.businessinfo.web_heading_key_name,
              button_icon: information.data.businessinfo.button_icon,
              button_text: information.data.businessinfo.button_text,
              button_action: information.data.businessinfo.button_action,
              button_message: information.data.businessinfo.button_message,
              brandName: information.data.businessinfo.business.name,
              brandProfilePic: information.data.businessinfo.businesslogo,
              username: information.data.businessinfo.insta_handle,
              imagesList: webproducts,
              // id: data.id,
              // isPrivate: data.is_private,
              // isVerified: data.is_verified,
              // biography: data.biography,
              // followedBy: data.edge_followed_by.count,
              // following: data.edge_follow.count,
              // imagesList,
              productDetail: productDetail
                ? productDetail
                : {
                    imageUrl: null,
                    shortcode: null,
                    imageId: null,
                    thumbnail: null,
                    productDescription: null,
                    isVideo: null,
                  },
              snap_pixel_script:
                information.data.businessinfo.snap_pixel_script,
              googlemaplink: information.data.businessinfo.googlemaplink,
              telephoneNumber: information.data.businessinfo.callnumber,
              whatsappnumber: information.data.businessinfo.whatsappnumber,
              whatsappLink: `${information.whatsapp_link_url}phone=${information.data.businessinfo.whatsappnumber}`,
              //whatsappLink: `https://api.whatsapp.com/send?phone=${information.data.businessinfo.whatsappnumber}`,
              instagramLink: `https://www.instagram.com/${information.data.businessinfo.insta_handle}`,
              tiktokLink: `https://www.tiktok.com/${information.data.businessinfo.tiktok_handle}`,
              tiktok_handle: information.data.businessinfo.tiktok_handle,
              instagram_campaign_enabled:
                information.data.businessinfo.instagram_campaign_enabled,
              snapchat_handle: information.data.businessinfo.snapchat_handle,
              twitter_handle: information.data.businessinfo.twitter_handle,
              insta_handle: information.data.businessinfo.insta_handle,
              no_of_products_to_show: information.data.no_of_products_to_show,
              youtube_handle: information.data.businessinfo.youtube_handle,
              viewMore: information.data.businessinfo.viewMore,
              buttonInstagram: information.data.businessinfo.buttonInstagram,
              buttonWhatsapp: information.data.businessinfo.buttonWhatsapp,
              buttonPhone: information.data.businessinfo.buttonPhone,
              buttonSnapchat: information.data.businessinfo.buttonSnapchat,
              buttonTwitter: information.data.businessinfo.buttonTwitter,
              buttonMap: information.data.businessinfo.buttonMap,
              buttonCalendar: information.data.businessinfo.buttonCalendar,
              buttonYoutube: information.data.businessinfo.buttonYoutube,
              websitelink: information.data.businessinfo.websitelink,
              showIconButtonNames:
                information.data.businessinfo.showIconButtonNames === 1,
              buttonWebsite: information.data.businessinfo.buttonWebsite,
            };
            // console.log('accountInfo productList exist', accountInfo);

            return dispatch({
              type: actionTypes.SET_INSTA_ACCOUNT_DETAILS,
              payload: accountInfo,
            });
            // }

            // by default for all other campaigns get from instagram
          }
        } else {
          history.push({ pathname: "/404" });

          return dispatch({
            type: actionTypes.ERROR_INSTA_ACCOUNT_DETAILS,
            payload: information,
          });
        }
      }

      // };
    } catch (error) {
      console.log("error", error);
      console.log(
        "error insta account",
        error.response || error.message || "Oops! Something went wrong."
      );
      // Logic here to hit api for tracking errors
      // errorLoadingPage(getBusinessNameFromUrl(), error.message || error.response || "Oops! Something went wrong.")
      history.push({ pathname: "/404" });
      return dispatch({
        type: actionTypes.ERROR_INSTA_ACCOUNT_DETAILS,
        payload: {
          success: false,
          message:
            error.message || error.response || "Oops! Something went wrong.",
        },
      });
    }
  };
};

export const setProductDetail = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_PRODUCT_DATA,
      payload: data,
    });
  };
};

const checkImageExist = (stateImagesArray, imageId) => {
  return !stateImagesArray.includes(imageId);
};
// For HomePage loading more instagram post
export const loadMoreInstagramPost = (instaHandleId, instaEndCursor) => {
  return async (dispatch, getState) => {
    try {
      // dispatch({
      //   type: actionTypes.LOADING_MORE_INSTAGRAM_POST,
      //   payload: true
      // });
      const responseMedia = await axios.get(
        `https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${instaHandleId}","first":12,"after":"${instaEndCursor}"}`
      );
      // console.log("responseMediA", responseMedia.data);

      let mediaArray = [
        ...responseMedia.data.data.user.edge_owner_to_timeline_media.edges,
      ];

      var hasNextPage =
        responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
          .has_next_page;
      var end_cursor =
        responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
          .end_cursor;

      if (mediaArray && mediaArray.length > 0) {
        var imagesList = mediaArray.map((media) => {
          return {
            imageUrl: media.node.display_url,
            shortcode: media.node.shortcode,
            imageId: media.node.id,
            productDescription:
              media.node.edge_media_to_caption.edges.length > 0
                ? media.node.edge_media_to_caption.edges[0].node.text
                : "",
            isVideo: media.node.is_video,
          };
        });

        // imagesList = imagesList.filter(item => {
        //   return !item.isVideo;
        // });
        // check which to hide from all list
        const products_to_hide_list =
          getState().campaign.instagramAccountInfo.products_to_hide_list;
        if (products_to_hide_list && products_to_hide_list.length > 0) {
          // remove hidden products
          imagesList = imagesList.filter((objFromImagesList) => {
            return !products_to_hide_list.includes(objFromImagesList.imageId);
          });
        }

        // To Make the count as 12 always
        while (
          (imagesList.length % 12 !== 0 || imagesList.length === 0) &&
          hasNextPage &&
          end_cursor
        ) {
          const newList = await loadInstagramPostBasedOnQuery(
            12 - (imagesList.length % 12),
            instaHandleId,
            end_cursor,
            products_to_hide_list
          );
          end_cursor = newList.end_cursor;
          hasNextPage = newList.hasNextPage;
          var stateImagesList =
            getState().campaign.instagramAccountInfo.imagesList;

          imagesList = [...imagesList, ...newList.imagesList];
          //to remove repeat from previous array
          if (stateImagesList && stateImagesList.length > 0) {
            stateImagesList = stateImagesList.map(
              (stateImage) => stateImage.imageId
            );

            // eslint-disable-next-line no-loop-func
            imagesList = imagesList.filter((image) => {
              return checkImageExist(stateImagesList, image.imageId);
            });
          }
        }
        return dispatch({
          type: actionTypes.GET_MORE_INSTAGRAM_POST,
          payload: {
            imagesList,
            hasNextPage,
            end_cursor,
          },
        });
      }
    } catch (error) {
      // console.log("ERROR LOADING MORE", error.message || error.response);

      return dispatch({
        type: actionTypes.ERROR_GET_MORE_INSTAGRAM_POST,
        payload: {
          imagesList: [],
          instaHasNextPage: null,
          hasNextPage: null,
        },
      });
    }
    // console.log('imageListAfterSize', imagesList.length);
  };
};

//For searching product detail from post that have productId down in the list
export const findInstagramPostById = async (
  instaHandleId,
  instaEndCursor,
  productId
) => {
  try {
    const responseMedia = await axios.get(
      `https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${instaHandleId}","first":12,"after":"${instaEndCursor}"}`
    );
    // console.log("responseMediA", responseMedia.data);

    let mediaArray = [
      ...responseMedia.data.data.user.edge_owner_to_timeline_media.edges,
    ];

    // let hasNextPage =
    //   responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
    //     .has_next_page;
    let end_cursor =
      responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
        .end_cursor;

    if (mediaArray && mediaArray.length > 0) {
      var imagesList = mediaArray.map((media) => {
        return {
          imageUrl: media.node.display_url,
          shortcode: media.node.shortcode,
          imageId: media.node.id,
          productDescription:
            media.node.edge_media_to_caption.edges.length > 0
              ? media.node.edge_media_to_caption.edges[0].node.text
              : "",
          isVideo: media.node.is_video,
        };
      });

      // imagesList = imagesList.filter(item => {
      //   return !item.isVideo;
      // });
      const productDetail = imagesList.find(
        (product) => product.imageId === productId
      );
      if (productDetail) {
        return productDetail;
      }
      // recurssive call to find images from list
      else
        return await findInstagramPostById(
          instaHandleId,
          end_cursor,
          productId
        );
    }
  } catch (error) {
    // console.log("ERROR LOADING MORE", error.message || error.response);

    return { productDetail: null };
  }
  // console.log('imageListAfterSize', imagesList.length);
};

// Earlier webscraping from IG api open graph ql was done but not any more as it is not allowed by instagram

// For HomePage loading more instagram post based on query
export const loadInstagramPostBasedOnQuery = async (
  loadMoreImageNo,
  instaHandleId,
  instaEndCursor,
  products_to_hide
) => {
  try {
    const responseMedia = await axios.get(
      `https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${instaHandleId}","first":${loadMoreImageNo},"after":"${instaEndCursor}"}`
    );
    // console.log("responseMediA", responseMedia.data);

    let mediaArray = [
      ...responseMedia.data.data.user.edge_owner_to_timeline_media.edges,
    ];

    let hasNextPage =
      responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
        .has_next_page;
    let end_cursor =
      responseMedia.data.data.user.edge_owner_to_timeline_media.page_info
        .end_cursor;

    if (mediaArray && mediaArray.length > 0) {
      var imagesList = mediaArray.map((media) => {
        return {
          imageUrl: media.node.display_url,
          shortcode: media.node.shortcode,
          imageId: media.node.id,
          productDescription:
            media.node.edge_media_to_caption.edges.length > 0
              ? media.node.edge_media_to_caption.edges[0].node.text
              : "",
          isVideo: media.node.is_video,
        };
      });

      // imagesList = imagesList.filter(item => {
      //   return !item.isVideo;
      // });
      // check if hide list included
      if (products_to_hide && products_to_hide.length > 0) {
        imagesList = imagesList.filter((objFromImagesList) => {
          return !products_to_hide.includes(objFromImagesList.imageId);
        });
      }

      // if (imagesList.length === loadMoreImageNo || !hasNextPage || !end_cursor) {
      return {
        imagesList,
        hasNextPage,
        end_cursor,
      };
      // }
      // return await loadInstagramPostBasedOnQuery((loadMoreImageNo - imagesList.length), instaHandleId, end_cursor, products_to_hide);
    }
  } catch (error) {
    // console.log("ERROR LOADING MORE based on query", error.message || error.response);

    return {
      imagesList: [],
      hasNextPage: null,
      end_cursor: null,
    };
  }
};

export const updateBusinessLogo = (businessid, insta_handle) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `https://www.instagram.com/${insta_handle}/?__a=1`
      );
      if (
        response.data &&
        response.data.graphql &&
        response.data.graphql.user
      ) {
        const businesslogo = response.data.graphql.user.profile_pic_url;
        axios
          .post(`${baseURL}businesslogo`, {
            businessid,
            businesslogo,
          })
          .then((resp) => {
            console.log("resp", resp.data);
            return resp.data;
          })
          .catch((error) => {
            console.log("error saving", error.response || error.message);
          });
        return dispatch({
          type: actionTypes.UPDATE_ACCOUNT_INFO,
          payload: {
            brandProfilePic: businesslogo,
          },
        });
      }
    } catch (error) {
      // console.log('error', error.response || error.message);
    }
  };
};

export const errorLoadingPage = (businessname, errorReason) => {
  axios
    .post(`${baseURL}weberrorLog`, {
      businessurl: businessname,
      error_reason: errorReason,
    })
    .then((res) => console.log("res", res.data))
    .catch((err) => console.log("err", err));
};
