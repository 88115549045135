import React from "react";
// import { Animated } from "react-animated-css";
// import About from './components/about';
// import Feature from './components/feature';
// import Work from './components/work';
// import ScreenShot from './components/screenshot';
// import Team from './components/team';
// import Blog from './components/blog';
// import Price from './components/price';
// import Testimonial from './components/testimonial';
// import FAQ from './components/faq';
// import Contact from './components/contact';
// import Subscribe from './components/subscribe';
import Footer from "./components/NewFooter";
// import { Link, withRouter } from 'react-router-dom';
//Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Product from "./components/MainPageProduct";
import * as actionCreators from "./store/actions";
import { Container, Row, Col, Image } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import ReactLoading from "react-loading";
import $ from "jquery";
import OptimizeLogoIcon from "../src/assets/images/logo.png";
import CalendarIcon from "../src/assets/images/svgs/google-calendar.svg";
import YoutubeIcon from "../src/assets/svgs/PlatformLogo/YoutubeNew.svg";
import WhatsappIcon from "../src/assets/svgs/PlatformLogo/WhatsappLogoNew.svg";
import MobileIcon from "../src/assets/svgs/PlatformLogo/PhoneNew.svg";
import SnapchatIcon from "../src/assets/svgs/PlatformLogo/SnapchatNew.svg";
import WebsiteIcon from "../src/assets/svgs/PlatformLogo/WebsiteLogo.svg";
import TikTokIcon from "../src/assets/svgs/PlatformLogo/TikTok.svg";
import TwitterIcon from "../src/assets/svgs/PlatformLogo/TweeterNew.svg";
import InstagramIcon from "../src/assets/svgs/PlatformLogo/InstaNew.svg";
import GoogleLocationIcon from "../src/assets/svgs/PlatformLogo/GoogleLocationNew.svg";
import RoundVector from "../src/assets/svgs/RoundVector.svg";
import ArrowVector from "../src/assets/svgs/ArrowVector.svg";
import FacebookIcon from "../src/assets/svgs/PlatformLogo/FacebookAd.svg";

import isStringArabic from "./store/isStringArabic";
import { isMobile, isAndroid } from "react-device-detect";

import OPLogo from "../src/assets/svgs/OPLogo.svg";

export const getPlatformList = (businessWebsiteData) => {
  let platformList = [];

  if (businessWebsiteData.whatsappnumber) {
    platformList.push({
      platformName: "WhatsApp",
      platformValue: businessWebsiteData.whatsappnumber || "",
      iconName: WhatsappIcon,
      id: "whatsappLink",
    });
  }
  if (businessWebsiteData.telephoneNumber) {
    platformList.push({
      platformName: "Phone",
      platformValue: businessWebsiteData.telephoneNumber || "",
      iconName: MobileIcon,
      id: "telephoneNumber",
    });
  }
  if (businessWebsiteData.facebook_handle) {
    platformList.push({
      platformName: "Facebook",
      platformValue: businessWebsiteData.facebook_handle || "",
      iconName: FacebookIcon,
      id: "facebookLink",
    });
  }
  if (businessWebsiteData.insta_handle) {
    platformList.push({
      platformName: "Instagram",
      platformValue: businessWebsiteData.insta_handle || "",
      iconName: InstagramIcon,
      id: "instagramLink",
    });
  }
  if (businessWebsiteData.youtube_handle) {
    platformList.push({
      platformName: "Youtube",
      platformValue: businessWebsiteData.youtube_handle || "",
      iconName: YoutubeIcon,
      id: "youtubelink",
    });
  }
  if (businessWebsiteData.snapchat_handle) {
    platformList.push({
      platformName: "Snapchat",
      platformValue: businessWebsiteData.snapchat_handle || "",
      iconName: SnapchatIcon,
      id: "snapchatLink",
    });
  }
  if (businessWebsiteData.googlemaplink) {
    platformList.push({
      platformName: "Google Map",
      platformValue: businessWebsiteData.googlemaplink || "",
      iconName: GoogleLocationIcon,
      id: "googleMapLink",
    });
  }
  if (businessWebsiteData.twitter_handle) {
    platformList.push({
      platformName: "Twitter",
      platformValue: businessWebsiteData.twitter_handle || "",
      iconName: TwitterIcon,
      id: "twitterLink",
    });
  }
  if (businessWebsiteData.website_url) {
    platformList.push({
      platformName: "Website",
      platformValue: businessWebsiteData.website_url || "",
      iconName: WebsiteIcon,
      id: "websiteLink",
    });
  }
  if (businessWebsiteData.tiktok_handle) {
    platformList.push({
      platformName: "TikTok",
      platformValue: businessWebsiteData.tiktok_handle || "",
      iconName: TikTokIcon,
      id: "tiktokLink",
    });
  }

  return platformList;
};
// const rulesList = [
// 	{
// 		id: 1,
// 		text: "فيديو جديد بصوتك لمدة دقيقة"
// 	},
// 	{
// 		id: 2,
// 		text: " @ShaheenMusic فولو و منشن حساب "

// 	},
// 	{
// 		id: 3,
// 		text: " @ShaheenMusic متابعة حسابات "

// 	}
// ]
class Home extends React.Component {
  state = {
    showPowerText: false,
    showBrandLogo: true,
  };
  componentDidMount() {
    console.log("componentDidMount");
    // setTimeout(() => {
    // 	this.setState({
    // 		showPowerText: true
    // 	})
    // }, 3000)

    const { pathname } = this.props.location;
    // this.props.getLanguageListPOEdit(pathname === "/ar" ? "ar" : "en")

    this.props.getLanguageListPOEdit("ar");
    this.props.savePathName(pathname);
    if (isEmpty(this.props.instagramAccountInfo.brandName))
      this.props.getInstagramAccountDetail(this.props.history);
  }
  componentDidUpdate(prevProps) {
    if (
      !isNull(this.props.instagramAccountInfo.snap_pixel_script) &&
      !isEmpty(this.props.instagramAccountInfo.snap_pixel_script) &&
      prevProps.instagramAccountInfo.snap_pixel_script !==
        this.props.instagramAccountInfo.snap_pixel_script
    ) {
      var embedCode = this.props.instagramAccountInfo.snap_pixel_script; // <script>//my js script code</script>
      $("head").append(embedCode);
    }
  }
  errorLoadingPicture = (error) => {
    console.log("error", error);
    this.setState({
      showBrandLogo: false,
    });
    const { businessid, insta_handle } = this.props.instagramAccountInfo;
    this.props.updateBusinessLogo(businessid, insta_handle);
  };
  changeLanguage = () => {
    if (this.props.language === "en") {
      this.props.getLanguageListPOEdit("ar");
      this.props.history.replace("/ar");
    } else {
      this.props.getLanguageListPOEdit("en");
    }
  };

  onLinkClicks = (platformName) => {
    console.log("platformName", platformName);
    if (platformName === "WhatsApp") {
      if (isMobile) {
        window.open(
          `whatsapp://send?phone=${this.props.instagramAccountInfo.whatsappnumber.replace(
            "+",
            ""
          )}`
        );
      } else {
        window.open(this.props.instagramAccountInfo.whatsappLink);
      }
      return true;
    } else if (platformName === "Phone") {
      window.open(
        `tel:${this.props.instagramAccountInfo.telephoneNumber}`,
        "_self"
      );
    } else if (platformName === "Facebook") {
      window.open(
        `https://www.facebook.com/${this.props.instagramAccountInfo.facebook_handle}`
      );
    } else if (platformName === "Instagram") {
      if (
        !isNull(this.props.instagramAccountInfo.snap_pixel_script) &&
        !isEmpty(this.props.instagramAccountInfo.snap_pixel_script) &&
        this.props.instagramAccountInfo.snap_pixel_script
      ) {
        window.snaptr("track", "VIEW_CONTENT");
      }
      if (isMobile) {
        window.open(
          `instagram://user?username=${this.props.instagramAccountInfo.insta_handle}`,
          "_blank"
        );
      } else {
        window.open(this.props.instagramAccountInfo.instagramLink);
      }

      return true;
    } else if (platformName === "Youtube") {
      window.open(
        `https://www.youtube.com/@${this.props.instagramAccountInfo.youtube_handle}`
      );
    } else if (platformName === "Snapchat") {
      window.open(
        `https://www.snapchat.com/add/${this.props.instagramAccountInfo.snapchat_handle}`
      );
    } else if (platformName === "Google Map") {
      // window.location = `https://${this.props.instagramAccountInfo.googlemaplink}`;
      let url = `${this.props.instagramAccountInfo.googlemaplink}`;
      if (!url?.includes("https")) {
        url = `https://${url}`;
      }
      window.open(url, "_BLANK");
    } else if (platformName === "Twitter") {
      window.open(
        `https://twitter.com/${this.props.instagramAccountInfo.twitter_handle}`
      );
    } else if (platformName === "TikTok") {
      window.open(
        `https://tiktok.com/@${this.props.instagramAccountInfo.tiktok_handle}`
      );
    } else if (platformName === "Website") {
      console.log(
        "this.props.instagramAccountInfo.website_url",
        this.props.instagramAccountInfo.website_url
      );
      let websiteUrl = `${this.props.instagramAccountInfo.website_url}`;
      if (!websiteUrl?.includes("http")) {
        websiteUrl = `https://${websiteUrl}`;
      }
      window.open(websiteUrl, "_blank");
    }
  };

  render() {
    const translate = this.props.translate;
    document.body.classList.remove("inner-page");
    // console.log('this.props.instagramAccountInfo', this.props.instagramAccountInfo);
    // console.log('this.props.loadingInstaDetail', this.props.loadingInstaDetail);
    const linkDetailsArray = getPlatformList(this.props.instagramAccountInfo);

    // console.log("linkDetailsArray", linkDetailsArray);
    if (this.props.loadingInstaDetail) {
      return (
        <div className={"loaderDiv"}>
          <ReactLoading type="spin" color="#5410BF" />
        </div>
      );
    }

    const isLinkView =
      this.props.instagramAccountInfo.imagesList &&
      this.props.instagramAccountInfo.imagesList.length == 0;
    return (
      <div className={`outerDiv ${isLinkView && "outerDivH"}`}>
        <Image className="vectorTop" src={RoundVector} />
        <Image className="vectorBottom" src={RoundVector} />
        <div
          className={`topNavBar ${
            this.props.language === "ar" && "topNavBarRTL"
          } ${isMobile && isLinkView && "headPosition"}`}
          //style={!isLinkView && {}}
        >
          <Image className="headLogo" src={OPLogo} />
          <div
            className={`d-flex flex-column mx-2  ${
              this.props.language === "ar"
                ? "align-items-end"
                : "align-items-start"
            }  `}
          >
            <p
              className={`poweredSlideText ${
                this.props.language === "ar" && "arabic"
              }`}
            >
              {translate[`This site is Powered by Optimize App`]}
            </p>
            <a
              href="https://optimizeapp.onelink.me/XJRq/OAClientWeb"
              target="_blank"
              rel="noopener noreferrer"
              className={`getYoursText ${
                this.props.language === "ar" && "arabic"
              }`}
            >
              {translate[`Get yours now!`]}
            </a>
          </div>

          <p
            onClick={this.changeLanguage}
            className={`languageButton ${
              this.props.language === "en" && "arabic"
            } ${this.props.language === "ar" && " languageButtonRTL"}`}
          >
            {this.props.language === "en" ? "العربية" : "English"}
          </p>
        </div>
        {!isLinkView ? (
          <Container className="mainHome">
            <div className="innerBlock1New">
              {this.state.showBrandLogo && (
                <Row className="brandImageRow">
                  <Image
                    className="brandImage"
                    src={this.props.instagramAccountInfo.brandProfilePic}
                    onError={this.errorLoadingPicture}
                  />
                </Row>
              )}
              <div className="headerSection">
                <Row
                  className="brandNameTextNew"
                  style={{
                    fontFamily: isStringArabic(
                      this.props.instagramAccountInfo.brandName
                    )
                      ? "Araboto-bold"
                      : "Poppins-bold",
                  }}
                >
                  {this.props.instagramAccountInfo.brandName}
                </Row>
                {this.props.instagramAccountInfo.businessName ===
                  "bonabeel" && (
                  <Row className="brandNameSubText bonabeelText">
                    مبادر | مسوق | محاور
                  </Row>
                )}
                {this.props.instagramAccountInfo.brandName ===
                  "العجمان اليوم" && (
                  <Row className="brandNameSubText">
                    تغطيات مميزة . مقاطع حصرية
                  </Row>
                )}
                {
                  <div className="socialMediaButtons">
                    {linkDetailsArray.map((item) => {
                      return (
                        <Col className="columnDiv">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.id}
                            onClick={() => this.onLinkClicks(item.platformName)}
                          >
                            <Image
                              className="socialButtonImagesNew socialButtonLogoNew"
                              src={item.iconName}
                            />
                          </a>

                          <p
                            className={`socialMediaName ${
                              this.props.language === "ar"
                                ? "  arabic-bold"
                                : " "
                            }`}
                          >
                            {translate[item.platformName]}
                          </p>
                        </Col>
                      );
                    })}

                    {/* {this.props.instagramAccountInfo.businessName ===
                      "bonabeel" && (
                      <Col className="columnDiv">
                        <a
                          href={"https://bonabeel.co/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          id="websiteLink"
                        >
                          <Image
                            className="socialButtonImagesNew socialButtonLogoNew websiteLogo"
                            src={WebsiteIcon}
                          />
                        </a>
                        {this.props.instagramAccountInfo
                          .showIconButtonNames && (
                          <p className="socialMediaName arabic bonabeelRegText">
                            الإشتراك بالدورة
                          </p>
                        )}
                      </Col>
                    )} */}
                  </div>
                }
                {this.props.errorMessage && (
                  <Row className="errorMessageText">
                    {this.props.errorMessage}
                  </Row>
                )}
              </div>
            </div>
          </Container>
        ) : (
          <Container className="mobileFlex">
            {/* <div className="innerBlockLink"> */}
            <div className="headerDetailsLink">
              {this.state.showBrandLogo && (
                <Row className="brandImageRowLink">
                  <Image
                    className="brandImage"
                    src={this.props.instagramAccountInfo.brandProfilePic}
                    onError={this.errorLoadingPicture}
                  />
                </Row>
              )}
              <Row
                className="brandNameTextNew"
                style={{
                  paddingTop: 10,
                  paddingLeft: 0,
                  fontFamily: isStringArabic(
                    this.props.instagramAccountInfo.brandName
                  )
                    ? "Araboto-bold"
                    : "Poppins-bold",
                }}
              >
                {this.props.instagramAccountInfo.brandName}
              </Row>
            </div>
            {/* connect with us */}
            <div className="connectUsMain">
              <h4
                className={`connectUsText ${
                  this.props.language === "ar" && " arabic-bold"
                }`}
              >
                {translate["Connect with us"]}
              </h4>
              {/* link cards */}
              {linkDetailsArray.map((item) => {
                return (
                  <div
                    className="linkCardsMain"
                    onClick={() => this.onLinkClicks(item.platformName)}
                    id={item.id}
                  >
                    <div className="linkDetails" id={item.id}>
                      <Image
                        id={item.id}
                        className="linkCardImage"
                        src={item.iconName}
                      />
                      <div id={item.id} className="contactInfo">
                        <span
                          id={item.id}
                          className={`contactus-text ${
                            this.props.language === "ar" && "arabic-bold"
                          }`}
                        >
                          {translate[item.platformName]}
                        </span>
                        <span id={item.id} style={{ fontSize: "10px" }}>
                          {isMobile
                            ? item.platformValue.substring(0, 30)
                            : item.platformValue.substring(0, 50)}
                        </span>
                      </div>
                    </div>
                    <Image
                      id={item.id}
                      className="linkCardImage"
                      src={ArrowVector}
                    />
                  </div>
                );
              })}
            </div>
            {/* </div> */}
          </Container>
        )}
        {/* remove below hardcoded false condition */}
        {!isLinkView &&
          this.props.instagramAccountInfo.imagesList &&
          this.props.instagramAccountInfo.imagesList.length > 0 && (
            <div className="productMain">
              <Container
                className={`productsListContainer ${
                  this.props.instagramAccountInfo.imagesList &&
                  this.props.instagramAccountInfo.imagesList.length === 1
                    ? "bottomPadding"
                    : this.props.instagramAccountInfo.imagesList &&
                      this.props.instagramAccountInfo.imagesList.length === 2
                    ? "bottomPadding-2"
                    : this.props.instagramAccountInfo.imagesList &&
                      this.props.instagramAccountInfo.imagesList.length === 3
                    ? "bottomPadding-3"
                    : this.props.instagramAccountInfo.imagesList &&
                      (this.props.instagramAccountInfo.imagesList.length ===
                        4 ||
                        this.props.instagramAccountInfo.imagesList.length ===
                          5 ||
                        this.props.instagramAccountInfo.imagesList.length === 6)
                    ? "bottomPadding-456"
                    : "padding-default"
                }`}
              >
                {/* <div className="ctaHeading">
                  <h4
                    style={{
                      color: "#27272E",
                      fontFamily: isStringArabic(
                        translate[
                          this.props.instagramAccountInfo.web_heading_key_name.toUpperCase()
                        ]
                      )
                        ? "Araboto-bold"
                        : "Poppins-bold",
                    }}
                  >
                    {
                      translate[
                        this.props.instagramAccountInfo.web_heading_key_name.toUpperCase()
                      ]
                    }
                  </h4>
                </div> */}
                <Row className="productsListRow">
                  {this.props.instagramAccountInfo.imagesList &&
                    this.props.instagramAccountInfo.imagesList.map((image) => (
                      <Product
                        key={image.imageId}
                        media={image}
                        businessName={
                          this.props.instagramAccountInfo.businessName
                        }
                        whatsappLink={
                          this.props.instagramAccountInfo.whatsappLink
                        }
                        productLength={
                          this.props.instagramAccountInfo.imagesList.length
                        }
                        setProductDetail={this.props.setProductDetail}
                        productDetail={image}
                      />
                    ))}
                </Row>
                <a
                  onClick={() => {
                    this.props.history.push("/privacypolicy");
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5
                    id="privacyPolicyNew"
                    style={{
                      fontFamily: isStringArabic(translate["Privacy Policy"])
                        ? "Araboto-bold"
                        : "Poppins-bold",
                      color:
                        // !this.props.imagesList ||
                        // this.props.imagesList.length === 0
                        //   ? "#FFFFFF"
                        // :
                        "#505780",
                    }}
                  >
                    {translate[`Privacy Policy`]}
                  </h5>
                </a>
              </Container>
              {/* To show view more button when loading images from instagram */}
              {this.props.instagramAccountInfo.end_cursor &&
                this.props.instagramAccountInfo.hasNextPage &&
                this.props.instagramAccountInfo.imagesList.length <
                  this.props.no_of_products_to_show && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      background: "#f4f4f4",
                      justifyContent: "center",
                      marginTop: -1,
                      marginBottom: -1,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    <button
                      style={{
                        cursor: "pointer",
                        alignSelf: "center",
                        background: "transparent",
                        border: "1px solid #ff790a",
                        borderRadius: 50,
                        color: "#ff790a",
                        height: 40,
                        width: 200,
                        fontFamily: isStringArabic(
                          translate[this.props.instagramAccountInfo.viewMore]
                        )
                          ? "Araboto-bold"
                          : "Poppins-bold",
                      }}
                      onClick={() =>
                        this.props.loadMoreInstagramPost(
                          this.props.instagramAccountInfo.id,
                          this.props.instagramAccountInfo.end_cursor
                        )
                      }
                    >
                      {translate[this.props.instagramAccountInfo.viewMore]}
                    </button>
                  </div>
                )}
            </div>
          )}
        {/*Footer Component*/}
        {isLinkView ? (
          <Container className="linkFooter">
            {/* <div className="poweredBy"> */}
            {/* <p
                className="copyrightNew"
                style={{
                  fontFamily: isStringArabic(translate["Powered by"])
                    ? "Araboto-bold"
                    : "Agrandir-Regular",
                  // color:
                  //   !this.props.imagesList || this.props.imagesList.length === 0
                  //     ? "#FFFFFF"
                  //     : "#c6c6c6",
                }}
              >
                {translate["Powered by"]}
              </p> */}
            {/* <a
                href="https://optimizeapp.onelink.me/XJRq/OAClientWeb"
                target="_blank"
                rel="noopener noreferrer"
              > */}
            {/* <h5
                  id="copyrightOptimizeApp"
                  // style={{
                  //   color:
                  //     !this.props.imagesList || this.props.imagesList.length === 0
                  //       ? "#FFFFFF"
                  //       : "#c6c6c6",
                  // }}
                >
                  OptimizeApp
                </h5> */}
            {/* </a> */}
            {/* </div> */}
            {/* </div> */}

            <a
              onClick={() => {
                this.props.history.push("/privacypolicy");
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5
                id="privacyPolicyNew"
                style={{
                  fontFamily: isStringArabic(translate["Privacy Policy"])
                    ? "Araboto-bold"
                    : "Poppins-bold",
                  color:
                    !this.props.imagesList || this.props.imagesList.length === 0
                      ? "#505780"
                      : "#505780",
                }}
              >
                {translate[`Privacy Policy`]}
              </h5>
            </a>
          </Container>
        ) : (
          <Footer
            translate={this.props.translate}
            imagesList={this.props.instagramAccountInfo.imagesList}
            history={this.props.history}
            platformList={linkDetailsArray}
            handlePlatformClick={this.onLinkClicks}
            language={this.props.language}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getInstagramAccountDetail: (history) =>
    dispatch(actionCreators.getInstagramAccountDetail(history)),
  loadMoreInstagramPost: (instaHandleId, instaEndCursor) =>
    dispatch(
      actionCreators.loadMoreInstagramPost(instaHandleId, instaEndCursor)
    ),
  savePathName: (pathname) => dispatch(actionCreators.savePathName(pathname)),
  updateBusinessLogo: (businessid, insta_handle) =>
    dispatch(actionCreators.updateBusinessLogo(businessid, insta_handle)),
  getLanguageListPOEdit: (language) =>
    dispatch(actionCreators.getLanguageListPOEdit(language)),

  setProductDetail: (productDetail) =>
    dispatch(actionCreators.setProductDetail(productDetail)),
});
const mapStateToProps = (state) => ({
  language: state.websiteLanguage.language,
  instagramAccountInfo: state.campaign.instagramAccountInfo,
  errorMessage: state.campaign.errorMessage,
  loadingInstaDetail: state.campaign.loadingInstaDetail,
  no_of_products_to_show: state.campaign.no_of_products_to_show,
  pathname: state.login.pathname,
  translate: state.websiteLanguage.translations,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
